@import '../../styles/variables.scss';
@import '../mixins.scss';
@import '../variables.scss';

.accountTypeTabs {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 3.125rem;

  &Button {
    @include clickable;
    color: $cerulean;
    font-family: $font-family-strong;
    font-size: $font-size-medium;
    padding: 0;
    text-decoration: underline;

    & + & {
      margin-left: 1.875rem;
    }

    &.isSelected {
      color: $navy-blue;
      text-decoration: none;
      &:hover {
        cursor: default;
      }
    }
  }
}
