@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';
@import '../mixins.scss';
@import '../variables.scss';

$planWidth: 17.5rem;
$planSpacing: 2rem;

.switchPlanType {
  display: flex;
  height: 65px;

  &Button {
    color: $navy-blue;
    font-family: $font-family-strong;
    font-size: $font-size-medium;
    @include clickable;

    &:hover {
      text-decoration: underline;
    }

    &.isSelected {
      color: $cerulean;
      text-decoration: underline;
    }
  }
}

.subscriptionPage {
  font-size: $font-size-medium;

  > .section + .section {
    padding: 1em 0 2em;
    display: flex;
    flex-direction: column;
  }
}

.creditBundles {
  padding: 3rem 0;
}

.sectionTitle {
  color: $cerulean;
}

.text {
  font-family: $font-family-regular;
  max-width: 30.625rem;
}

.editPlan {
  padding-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.changePlan {
  display: flex;
  max-width: 17rem;
}

.activeButton {
  @include primaryAccountButton();

  &:disabled {
    background-color: $white;
    color: $moderate-lime-green;
    font-size: $font-size-large;
  }
}

.subscribeButton {
  @include button($mostly-pure-yellow, $navy-blue, $white, $navy-blue);
  font-family: $font-family-strong;

  &:disabled {
    background-color: $lightgrey;
  }
}

.trialButton {
  @include primaryAccountButton();

  &:disabled {
    background-color: $cerulean;
    color: $white;
  }
}

.comingSoonButton {
  @include primaryAccountButton();

  &:disabled {
    background-color: $white;
    color: $dark-greyish-orange;
    font-size: $font-size-large;
  }
}

.label {
  padding-bottom: 0.35em;
}

a {
  font-family: $font-family-regular;
  color: $cerulean;
  white-space: nowrap;
}

.comparePlans {
  padding: 1rem 0;
  display: grid;
  column-gap: 2em;
  row-gap: 2em;
  grid-template-columns: repeat(2, $planWidth);

  .creditBundles & {
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint(screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }

  @include breakpoint(screen-lg, max) {
    column-gap: 1em;
    row-gap: 1em;
  }

  @include breakpoint(screen-sm, max) {
    grid-template-columns: 1fr;
  }
}

.selectPeriod {
  display: flex;
  justify-content: center;
  font-family: $font-family-regular;

  &.isDisabled {
    opacity: 0.65;
    pointer-events: none;
  }

  label + label {
    padding-left: 1em;
  }

  .discount {
    font-family: $font-family-strong;
    color: $moderate-lime-green;
  }

  .labelText {
    padding-right: 0.5em;
  }

  @include breakpoint(screen-sm, max) {
    padding-top: 0.5em;
  }
}

.currencyAndPeriodSection {
  width: 100%;
  max-width: ($input-width * 2) + 2rem;

  .inner {
    padding-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(screen-sm, max) {
      flex-direction: column;
    }
  }
}

.faqSection {
  $spacing: 1rem;

  max-width: 46.5rem;
  color: $navy-blue;

  .faqHeader {
    margin: 0 0 $spacing 0;
    font-size: $font-size-extra-extra-large;
    font-family: $font-family-strong;
  }
  .faqContent {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $spacing;
  }
  .faqQuestion {
    font-size: $font-size-large;
    margin: 0;
  }
  .faqAnswer {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    margin: 0;
  }
}

.ctaSection {
  $spacing: 1rem;
  $headerLineHeight: 1.875rem;

  max-width: 46.5rem;

  .ctaSubheader {
    color: $cerulean;
    font-size: $font-size-18;
    line-height: $headerLineHeight;
    margin: 0;
    font-family: $font-family-strong;
  }
  .ctaHeader {
    color: $navy-blue;
    margin: 0 0 1rem 0;
    font-size: $font-size-extra-extra-large;
    line-height: $headerLineHeight;
    font-family: $font-family-strong;
  }
  .ctaDescription {
    font-size: $font-size-medium;
    color: $navy-blue;
    font-family: $font-family-regular;
    margin: 0 0 $spacing 0;
  }
  .ctaLink {
    display: block;
    font-size: $font-size-medium;
    color: $cerulean;
    font-family: $font-family-regular;
    margin: 0 0 $spacing * 2 0;
    text-decoration: underline;

    &Disabled {
      pointer-events: none;
      color: $navy-blue;
    }

    b {
      font-family: $font-family-strong;
    }
  }
}

.deleteLink {
  font-family: $font-family-regular;
  font-size: $font-size-medium;
  color: $warning-color;
}

.selectCreditBundle {
  padding-bottom: 1rem;
  font-size: $font-size-18;
}

.modalText {
  font-family: $font-family-regular;
  font-size: $font-size-large;
  margin: 0 0 1em;
  white-space: pre;
}

.restrictedCurrencyInfo {
  padding-bottom: 0.5em;
}
