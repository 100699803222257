@import '../../styles/variables.scss';

.faqSection {
  $spacing: 1rem;

  max-width: 46.5rem;
  color: $navy-blue;

  .faqHeader {
    margin: 0 0 $spacing 0;
    font-size: $font-size-extra-extra-large;
    font-family: $font-family-strong;
  }
  .faqContent {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $spacing;
  }
  .faqQuestion {
    font-size: $font-size-large;
    margin: 0;
  }
  .faqAnswer {
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    margin: 0;
    white-space: pre-wrap;
  }
}
