@import '../../styles/variables.scss';
@import '../variables';

.selectPort {
  font-family: $font-family-regular;
  font-size: $font-size-regular;
  position: relative;
  width: $input-width;
  max-width: 100%;

  .unlocode {
    display: block;
    color: $darkgrey;
    font-size: $font-size-regular;
  }

  .input {
    width: 100%;
  }
}
