@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/spaced.scss';
@import '../variables.scss';
@import '../../UI/Toggle/toggleTheme.scss';

.overviewPage {
  font-size: $font-size-medium;

  > * + * {
    padding: 1em 0 2em;
    display: flex;
    flex-direction: column;
  }

  .title {
    padding: 0;
  }

  .sectionCaption {
    color: $cerulean;
  }

  .sectionText {
    font-family: $font-family-regular;
    white-space: pre-wrap;
  }

  .expiryNotice {
    display: flex;
    flex-direction: column;
    padding: 0.5em 0 1.5em;
    .notice {
      color: $warning-color;
    }
    .autoChange {
      font-family: $font-family-regular;
    }
  }

  .editPlan {
    padding-top: 1.25rem;
    padding-bottom: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .label {
    padding-bottom: 0.35em;
  }

  a {
    font-family: $font-family-regular;
    color: $cerulean;
  }

  .nextPayment {
    font-family: $font-family-regular;
  }

  .creditCard {
    .number {
      display: flex;
      align-items: center;
    }

    input {
      color: $darkgrey;
      border: none;
    }

    a {
      margin-top: 1.25em;
    }
  }

  .marketingCheckbox {
    @include spaced-horizontal(1rem);

    flex-direction: row;
    font-family: $font-family-regular;
  }

  .loadingContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    &Text {
      padding-left: 0.5rem;
    }
  }

  .toggle {
    @include toggleTheme($cloud-grey, $cerulean, $white, $white, $white, $white, $cloud-grey);

    &:hover {
      outline: none;
    }
  }

  .autoTopUp {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      display: flex;
      align-items: center;
    }

    &Text {
      @include clickable;
      padding-left: 0.5em;
      font-size: $font-size-tiny;
      font-family: $font-family-regular;

      strong {
        font-size: $font-size-medium;
        font-family: $font-family-strong;
      }
    }
  }
}
