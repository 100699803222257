@import '../../styles/variables';
@import '../../styles/z-indices.scss';
@import '../../styles/mixins.scss';
@import '../../styles/spaced.scss';
@import '../../styles/spacing.scss';

$theme-border-color: var(--border-color);
$drawer-background-color: var(--tertiary-color);
$drawer-color: var(--text-color);
$border: 1px $theme-border-color solid;

.drawer {
  position: fixed;
  transition: transform 0.3s ease-out;
  will-change: transform;
  display: flex;
  flex-direction: column;
  z-index: $drawerIndex;
  max-height: 100vh;
  color: $drawer-color;

  &Bottom {
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    background-color: $drawer-background-color;

    &.active {
      transform: translateY(0);
    }
  }

  &Right {
    right: 0;
    height: 100%;
  }

  &Header {
    $headerItemPadding: 0 spacing(4);
    height: $drawerHeaderHeight;
    border-bottom: $border;
    display: flex;

    .actions {
      display: flex;
      width: 100%;

      .content {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .item {
        display: flex;
        font-size: $font-size-regular;
        padding: $headerItemPadding;
        box-sizing: border-box;
        align-items: center;
        font-family: $font-family-strong;
        color: var(--disabled-color);

        &:not(.disabled):not(.active) {
          color: var(--text-color);

          &:hover {
            cursor: pointer;
            background-color: $broken-white;

            @include inShipTrackerDarkMode {
              background-color: var(--primary-color);
            }
          }
        }
      }

      .button {
        outline: none;
        color: var(--disabled-color);

        &.active {
          color: $navy-blue;
          padding-top: 3px;
          border-bottom: 3px solid var(--accent-color);

          @include inShipTrackerDarkMode {
            color: var(--accent-color);
          }
        }
      }

      .actionItem {
        @include spaced-horizontal(4px);

        border-left: $border;
        font-family: $font-family-regular;

        .icon {
          font-size: $font-size-large;
        }

        &:hover {
          .label {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
