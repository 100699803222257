@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';
@import '../mixins.scss';
@import '../variables.scss';

.selectPlan {
  max-width: $input-width;
  min-height: 6.25rem;
  display: flex;
  box-sizing: border-box;
  padding: 0.75em 1em 0.75em 0;
  background-color: $black-squeeze;

  &.apiCredits {
    background-color: $peppermint;
  }

  h3 {
    margin: 0;
  }

  .segment + .segment {
    border-left: solid 1px $light-greyish-blue;
  }
  .segment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2em;

    &.plan {
      flex-grow: 1;
    }

    .text {
      font-family: $font-family-regular;
      white-space: nowrap;
    }
    .titleLight {
      font-family: $font-family-regular;
    }
  }
}

.expired {
  text-align: center;
  color: $warning-color;
  display: flex;
  flex-direction: column;

  .text {
    font-family: $font-family-regular;
  }

  &.premiumExpired {
    grid-column: 2;
    grid-row: 2;

    @include breakpoint(screen-sm, max) {
      grid-column: 1;
      grid-row: 3;
    }
  }
}
