@import '../../styles/variables';
@import '../../styles/mixins';

.themeScreen {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
  color: white;

  &.purple {
    background-color: $grape-color;
  }
  &.blue {
    background-color: $deep-blue;
  }
  &.green {
    background-color: $sea-green;
  }
  &.grey {
    background-color: $metal-grey;
  }

  .accountLogo {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem;

    .svg {
      fill: $white;
    }
  }
}
