@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

$form-width: 17.5rem;
$form-column-width: 8rem;

.creditCardForm {
  max-width: $form-width;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
    width: 100%;
  }

  > * + * {
    margin-top: 1em;
  }

  label {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    white-space: nowrap;
    display: flex;
    flex-direction: column;

    .label {
      padding-bottom: 0.4em;
    }

    input {
      width: 100%;
      max-width: 100%;
      color: $darkgrey;
    }
  }
}

.submitSection {
  margin-top: 2.5em;
}

.creditCardNumber {
  position: relative;

  .brandIcon {
    position: absolute;
    top: 0.9em;
    left: 1em;
    height: 1em;

    &.disabled {
      opacity: 0.65;
    }

    > svg {
      height: 100%;
      width: auto;
    }
  }

  input {
    padding-left: 3em;
  }
}

.creditCardExpiration {
  label {
    max-width: $form-column-width;
  }
}

.creditCardCVC {
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      max-width: $form-column-width;
    }

    .leftColum {
      width: $form-column-width;
      display: flex;
      flex-direction: column;
    }

    > a {
      width: $form-column-width;
      text-align: center;
      color: $cerulean;
    }
  }
}

.encryptionInfo {
  font-family: $font-family-regular;
  font-size: $font-size-medium;
  color: $greyish-orange;
  white-space: nowrap;

  .lockIcon {
    font-size: 18px;
    vertical-align: text-bottom;
    padding-right: 0.2em;
  }
}
