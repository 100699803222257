@import '../../styles/variables.scss';
@import '../variables.scss';

.apiKeyPage {
  color: $navy-blue;

  .section {
    & + .section {
      padding-top: 1rem;
    }
  }

  .text {
    margin: 0 0 1.5rem;
    font-size: $font-size-large;
    line-height: 18px;
    font-family: $font-family-regular;
    color: $navy-blue;
    white-space: pre-wrap;
  }

  .button {
    width: 10rem;
    padding-right: 0;
    padding-left: 0;
  }
}
