@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.selectPort {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 100%;

  .dropdown {
    position: relative;

    .label {
      color: var(--text-color);
      font-size: $font-size-medium;
      padding-bottom: 0.5rem;
    }

    .inputWrapper {
      position: relative;
      padding-bottom: 0.75rem;

      .input {
        color: var(--text-color);
        border: 1px var(--border-color) solid;
        padding: 0.75rem;
        border-radius: 1px;
        padding-left: 2.25rem;
        font-family: $font-family-strong;
        font-size: $font-size-regular;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }
      }

      .placeIcon {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        $size: 1.5rem;
        width: $size;
        height: $size;
        background-color: var(--accent-color);
        mask-image: url('./icons/place-24px.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 100%;
      }
    }

    .unlocode {
      display: block;
      font-size: $font-size-regular;
      @include inShipTrackerDarkMode {
        color: $alto;
      }
    }
  }
}
