@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$form-width: 17.5rem;
$form-column-width: 8rem;

.submitSection {
  margin-top: 2.5em;
}

.submit:not(:disabled) {
  @include button($mostly-pure-yellow, $navy-blue, $white, $navy-blue);

  &:focus {
    outline: none;
  }
}

.submit {
  width: 100%;
  font-family: $font-family-strong;
  font-size: $font-size-medium;
  padding: 0.8125rem;
  background-color: $light-greyish-orange;
  color: $greyish-orange;
}
