@import '../../styles/variables.scss';
@import '../../styles/spaced';
@import '../mixins';
@import '../variables';

.completeAccountSection {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  padding: 8rem 1rem 2rem;
  align-items: center;
  max-width: 40rem;
}

.description {
  font-family: $font-family-regular;
  font-size: $font-size-medium;
  margin: 0;
  padding-bottom: 1.5rem;
  width: 100%;
  max-width: 50rem;
  white-space: pre-wrap;
}

.title {
  font-size: $font-size-extra-extra-extra-large;
  font-family: $font-family-strong;
  margin: 0;
}

.subTitle {
  font-size: $font-size-extra-extra-extra-large;
  font-family: $font-family-strong;
  margin-bottom: 1.75rem;
}

.completeAccountContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formActions {
  width: $input-width;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-family: $font-family-regular;
}

.checkbox {
  @include spaced-horizontal(1rem);
  font-family: $font-family-regular;
}

.submitButton {
  @include primaryAccountButton();
  box-sizing: border-box;
  text-align: center;
  font-family: $font-family-strong;
  max-width: $input-width;
}

.legalTerms {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;

  .section {
    font-size: $font-size-medium;

    & + .section {
      padding-top: 2rem;

      @include breakpoint(screen-sm, max) {
        padding-top: 1rem;
      }
    }

    .subSection + .subSection {
      padding-top: 2rem;
    }
  }

  .continue {
    padding-top: 2rem;
    width: $input-width;
  }
}

.payment {
  font-size: $font-size-medium;

  > .section {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;

    & + .section {
      padding-top: 1em;
    }
  }

  .title {
    font-size: $font-size-extra-large;
    margin-bottom: 1.75rem;
  }

  .selectPeriod {
    display: flex;
    font-family: $font-family-regular;
    margin: 3rem 0 2rem;

    label + label {
      padding-left: 1em;
    }

    .discount {
      font-family: $font-family-strong;
      color: $moderate-lime-green;
    }
  }
}

.blocker {
  $spinnerSize: 1em;
  font-size: $font-size-medium;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  z-index: 1;

  display: flex;
  flex-direction: colum;
  align-items: center;
  justify-content: center;

  color: $lightgrey;

  .lightBackgroundSpinner {
    @include spinner($spinnerSize, 0, $lightgrey, transparent);
  }

  .loaderInline {
    position: relative;
    width: $spinnerSize;
    height: $spinnerSize;
    padding-right: 0.5rem;
  }
}
