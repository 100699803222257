@import '../../styles/variables.scss';

.vesselsLegend {
  width: 100%;
  display: flex;

  .cell {
    display: flex;
    padding: 1em;
    border: dashed 1px $cloud-grey;
  }

  .explanation {
    flex-direction: column;
    font-family: $font-family-regular;
  }

  .icons {
    border-left: none;
    align-items: flex-end;

    > svg {
      padding-left: 1em;
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
