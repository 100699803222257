@import './variables';

.StripeElement {
  padding: 0.75em 1em;
  border-radius: 1px;
  border: solid 1px $cloud-grey;
  background-color: $white;
  &:focus {
    outline: none;
  }
}

.StripeElement--invalid {
  border-color: $warning-color;
}
