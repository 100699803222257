@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';

.planOption {
  font-family: $font-family-regular;
  font-size: $font-size-medium;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  border: 1px $light-greyish-blue solid;
  background-color: $white;
  padding-bottom: 1.25rem;
  display: flex;
  flex-grow: 2;
  flex-direction: column;

  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  @include inShipTrackerDarkMode {
    border-color: $blue-bayoux;
    background-color: transparent;

    .header {
      background-color: transparent;
      border-bottom: 1px $blue-bayoux solid;
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1.25rem;

    .listing {
      flex-grow: 1;
      padding: 0 0 2.125rem 0;
      list-style: none;

      .item {
        padding-top: 1em;
        display: flex;
        align-items: center;

        .icon {
          position: relative;
          margin-right: 0.5em;
          color: $cerulean;

          &:before {
            border-radius: 50%;
            content: '';
            position: absolute;
            left: 0.125rem;
            top: 0.125rem;
            width: 0.775rem;
            height: 0.775rem;
            background-color: $white;
          }

          .muiIcon {
            position: relative;
            font-size: 1rem;
          }
        }

        .description {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.purpleTheme {
    color: $navy-blue;
    border: none;

    .header {
      background-color: $wisteria;
    }
  }

  &.disabled {
    color: $dark-greyish-orange;
    border-color: $light-greyish-orange;

    .header {
      color: $dark-greyish-orange;
      background-color: $light-greyish-orange;
    }

    .features {
      .item {
        .icon {
          color: $dark-greyish-orange;
        }
      }
    }
  }

  &.isDimmed {
    $opacity: 0.35;
    border-color: rgba($light-greyish-blue, $opacity);

    .header {
      background-color: rgba($cerulean, $opacity);
    }

    .price {
      color: rgba($navy-blue, $opacity);
    }

    .features {
      .item {
        color: rgba($navy-blue, $opacity);
        .icon {
          color: rgba($cerulean, $opacity);
        }
      }
    }
  }

  .header,
  .price {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      padding-top: 1em;
      margin: 0;
      font-size: $font-size-extra-large;

      &.specialOffer {
        text-decoration: line-through;
      }
    }
  }

  .header {
    height: 5.375rem;
    color: $white;
    background-color: $cerulean;
  }
}
