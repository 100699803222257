@import '../../styles/variables.scss';
@import '../variables.scss';
@import '../mixins';

.defaultPortSection {
  padding-bottom: 2rem;

  .description {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    margin: 0;
    padding-bottom: 1.5rem;
    max-width: 25rem;
    white-space: pre-wrap;
  }

  .portSection {
    display: flex;

    @include breakpoint(screen-sm, max) {
      flex-direction: column;
    }
  }
  .inputSection {
    display: flex;
    padding-right: 2rem;
  }

  .availablePortsLink {
    color: $cerulean;
    padding-top: 2rem;
    font-family: $font-family-regular;
    font-size: $font-size-medium;
  }
}

.formActions {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  font-family: $font-family-regular;
  max-width: $input-width;
}

.vesselLegend {
  padding-top: 5em;
  max-width: 25rem;
  font-size: $font-size-medium;

  .vesselLegendTitle {
    margin: 0;
  }

  .vesselLegendEntry {
    padding-top: 1em;
  }
}
