@import '../../styles/variables.scss';

.selectCurrency {
  display: flex;
  font-family: $font-family-regular;

  label + label {
    padding-left: 1rem;
  }
}
