@import '../../styles/variables';
@import '../../styles/z-indices';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.notificationsContainer {
  $notificationWidth: 600px;
  $zoomControlsWidth: 30px;
  $zoomControlsSpacing: 10px;
  $leftSpace: $zoomControlsWidth + 2 * $zoomControlsSpacing;
  $rightSpace: $zoomControlsSpacing;

  width: $notificationWidth;
  max-width: calc(100% - #{$leftSpace} - #{$rightSpace});
  position: absolute;
  top: 10px;
  left: $leftSpace;
  z-index: $mapControlStripIndex;

  @keyframes notificationSlideIn {
    from {
      transform: translateX(calc(-100% - #{$leftSpace}));
    }
    to {
      transform: translateX(0);
    }
  }

  .notification {
    $backgroundColor: $grape-color;
    $titleColor: $white;
    $contentColor: $alto;
    $buttonColor: $mostly-pure-yellow;
    $buttonTextColor: $navy-blue;
    $spacing: 0.625rem;

    position: relative;
    display: flex;
    align-items: center;
    background: $backgroundColor;
    padding: $spacing;
    margin-bottom: $spacing;
    transform: translateX(calc(-100% - #{$leftSpace}));
    animation: notificationSlideIn 0.2s ease-out forwards;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      padding-left: $spacing;

      .title {
        font-size: $font-size-medium;
        font-family: $font-family-strong;
        color: $titleColor;
        margin: 0 0 0.125rem 0;
        padding-right: $font-size-large;
        white-space: pre-line;
      }

      .text {
        font-size: $font-size-regular;
        font-family: $font-family-regular;
        color: $contentColor;
        margin: 0;
        white-space: pre-line;

        a {
          color: inherit;
        }
      }
    }

    .cta {
      background-color: $buttonColor;
      color: $buttonTextColor;
      font-size: $font-size-regular;
      font-family: $font-family-strong;
      padding: 0.125rem 0.5rem;
      margin-top: $spacing;
      border-radius: 2px;
      margin-left: auto;
      cursor: pointer;
      text-decoration: none;
      appearance: none;
      outline: none;
      border: none;
    }

    .icon {
      color: $contentColor;
      font-size: $font-size-large;

      &Close {
        position: absolute;
        top: $spacing;
        right: $spacing;
        cursor: pointer;
      }
    }
  }
}
