@import '../../styles/variables.scss';

.copyPasteContainer {
  max-width: 450px;
  margin: 2.5rem 0;

  .copyPasteLabel {
    display: block;
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    padding-bottom: 0.3125rem;
  }

  .copyPasteFieldWrapper {
    $dotSize: 0.7rem;
    $verticalPadding: 0.75rem;
    $horizontalPadding: 1rem;
    $rightContentPadding: $dotSize + 2 * $horizontalPadding;

    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: $verticalPadding;
      right: $horizontalPadding;
      width: $dotSize;
      height: $dotSize;
      border-radius: 50%;
      background-color: $warning-color;
      transform: translateY(2px);
    }

    &Enabled {
      &::after {
        background-color: $moderate-lime-green;
      }
    }

    .copyPasteField {
      width: 100%;
      resize: none;
      margin-bottom: 2rem;
      background-color: $mine-shaft;
      color: $alto;
      padding: $verticalPadding $rightContentPadding $verticalPadding $horizontalPadding;
      outline: none;
      box-sizing: border-box;
    }
  }

  .copyPasteFooter {
    display: flex;
    align-items: center;

    .copyButton {
      margin-left: auto;
      background-color: transparent;
      color: $navy-blue;
      border: solid 1px $navy-blue;
    }
  }
}
