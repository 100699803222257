@import '../../styles/variables.scss';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

$account-drawer-width: $width-vessel-listing;

@keyframes accountDrawerSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.accountDrawer {
  width: $account-drawer-width;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: $font-size-medium;
  background-color: $white;
  color: $white;
  animation: accountDrawerSlideIn 0.2s ease-out;
  top: 0;

  @include breakpoint(screen-md, max) {
    width: 100%;
  }

  @include inShipTrackerDarkMode {
    background-color: var(--primary-color);
  }

  .header {
    display: flex;
    align-items: center;
    padding: 1rem;

    .logo {
      fill: var(--text-color);
    }
  }

  .title {
    margin: 0 0 1rem;
    padding: 0;
    font-size: $font-size-extra-large;
  }

  .text {
    font-family: $font-family-regular;
    font-size: $font-size-medium;
    white-space: pre-wrap;
    margin: 0 0 4rem;
  }
}

.section {
  padding: 1rem 1.5rem 4rem;

  &.top {
    position: relative;
    background-color: var(--primary-color);
    color: var(--text-color);

    .triangleDown {
      $height: 17px;
      position: absolute;
      bottom: -12px;
      @include css-triangle('bottom', var(--primary-color), $height, $height * 2);
    }
  }

  &.bottom {
    padding-top: 3rem;
    flex-grow: 2;
    background-color: $navy-blue;

    @include inShipTrackerDarkMode {
      background-color: $midnight;
    }
  }
}

.accountDrawerOverlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.button {
  $button-height: 40px;

  display: flex;
  width: 100%;
  height: $button-height;
  text-align: left;
  padding: 0;
  font-family: $font-family-strong;
  align-items: center;
  cursor: pointer;

  .icon {
    display: flex;
    width: $button-height;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-right: solid 1px rgba(#fff, 0.3);
    box-sizing: content-box;
  }

  &.web {
    color: $white;
    background-color: $navy-blue;

    @include inShipTrackerDarkMode {
      background-color: $cerulean;
    }

    .icon {
      svg {
        fill: $white;
      }
    }
  }
  &.api {
    background-color: $mostly-pure-yellow;

    .icon {
      svg {
        fill: $navy-blue;
      }
    }
  }
}

.closeButton {
  @include clickable();
  color: var(--text-color);
  margin-left: auto;
}

.closeButtonIcon {
  font-size: 20px;
  font-weight: bold;
}
