@import '../styles/variables.scss';
@import '../styles/mixins.scss';
@import '../styles/breakpoints.scss';
@import '../Account/variables.scss';

$header-section-width: 1200px;
$header-content-width: 620px;
$header-content-height: 630px;

$section-width: 920px;

$fixed-button-width: 12.5rem;

.landingPage {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: var(--primary-color);
  color: var(--text-color);

  .content {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: scroll;
  }
}

.topSection {
  display: flex;
  flex-direction: column;
  position: relative;
  height: $header-content-height; // size of header image
  background-color: $lochmara;
  color: $white;
  overflow-x: hidden;
  padding: 0 1rem;

  @include inShipTrackerDarkMode {
    background-color: transparent;
  }

  @include breakpoint(screen-md, max) {
    height: auto;
  }

  .contentWrapper {
    width: $header-section-width;
    max-width: 100%;
    margin: 0 auto;
    z-index: 1;

    .headerContent {
      width: $header-content-width;
      margin: 4rem 0;
      background-image: linear-gradient(to right, $lochmara 70%, transparent);

      @include inShipTrackerDarkMode {
        background-image: linear-gradient(to right, $navy-blue 70%, transparent);
      }

      @include breakpoint(screen-md, max) {
        width: 100%;
        margin: 0;
        padding: 4rem 0;
      }

      .headerTitle {
        margin: 0 0 1rem;
        font-size: $font-size-34;
        font-family: $font-family-strong;
      }

      .actions {
        display: grid;
        grid-gap: 2.5rem;
        grid-template-columns: repeat(2, $fixed-button-width);
        margin: 6rem 0 0;

        @include breakpoint(screen-md, max) {
          width: 100%;
          grid-gap: 1.5rem;
          grid-template-columns: 1fr;
          margin: 4rem 0 0 0;
        }
      }
    }
  }

  .image {
    display: block;
    position: absolute;
    width: auto;
    height: 100%;
    right: 0;
    bottom: 0;
    object-fit: contain;
    z-index: 0;

    @include breakpoint(screen-md, max) {
      display: none;
    }
  }
}

.title {
  width: 60%;
  margin: 0 0 2rem;
  font-size: $font-size-extra-extra-extra-large;

  @include breakpoint(screen-md, max) {
    width: 100%;
  }

  &.centered {
    text-align: center;
  }
}

.subtitle {
  margin: 0;
  font-size: $font-size-extra-large;
  margin: 0 0 1.5rem;
}

.paragraph {
  margin: 0 0 3rem;
  font-size: $font-size-18;
  font-family: $font-family-regular;

  &.centered {
    text-align: center;
  }
  &.noSpacing {
    margin-bottom: 0;
  }

  strong {
    font-family: $font-family-strong;
  }
}

.list {
  margin: 0 1rem;
  padding: 0;
  list-style: none;

  &Header {
    font-size: $font-size-18;
    font-family: $font-family-strong;
  }

  .item {
    display: flex;
    margin: 0.5rem 0;
    align-items: center;
    font-size: $font-size-large;
    font-family: $font-family-regular;

    .icon {
      position: relative;
      color: $navy-blue;
      background-color: $white;
      clip-path: circle(40%);
      margin-right: 0.25rem;

      @include inShipTrackerDarkMode {
        color: $cerulean;
      }
    }
  }
}

.section {
  padding: 0 1rem;
  background-color: var(--primary-color);

  &.light {
    background-color: $black-squeeze;

    @include inShipTrackerDarkMode {
      background-color: $san-juan;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $section-width;
    margin: 0 auto;
    padding: 5rem 0;
    overflow: visible;
  }
}

.twoColumns {
  width: 100%;
  display: grid;
  margin: 4rem 0;
  grid-template-columns: 25rem 25rem;
  grid-column-gap: 12rem;

  @include breakpoint(screen-xl, max) {
    grid-column-gap: 6rem;
  }
  @include breakpoint(screen-lg, max) {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint(screen-md, max) {
    grid-template-columns: 1fr;
  }

  .item {
    overflow-x: visible;

    @include breakpoint(screen-lg, max) {
      width: 100%;
      overflow: hidden;

      p {
        width: 100% !important;
      }
    }
  }
}

.ctaFixedWidth {
  width: $fixed-button-width;
}

.imageWithText {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 5.5rem;
  padding: 3rem 0;

  &.isInverted {
    grid-template-columns: 3fr 2fr;

    @include breakpoint(screen-md, max) {
      grid-template-columns: 1fr;

      .image {
        grid-row: 1;
      }
    }
  }

  @include breakpoint(screen-md, max) {
    grid-template-columns: 1fr;
  }

  .image {
    display: block;
    margin-left: auto;
    width: 100%;
    object-fit: contain;

    @include breakpoint(screen-md, max) {
      width: 50% !important;
      margin-right: auto;
      margin-bottom: 4rem;
    }

    @include breakpoint(screen-sm, max) {
      width: 75% !important;
    }
  }

  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      margin: 0;
      font-size: $font-size-medium;
      font-family: $font-family-regular;

      @include breakpoint(screen-md, max) {
        font-size: $font-size-18;
      }
    }
  }
}

.faq {
  max-width: 100%;
  color: var(--text-color);
  padding: 2rem 0 6rem;

  a {
    color: $cerulean;
  }
}

.cta {
  @include inShipTrackerDarkMode {
    background-color: rgba($white, 0.1);
  }
  color: var(--text-color);
}

.selectBundles {
  font-size: $font-size-18;
}

.restrictedCurrencyInfo {
  font-size: $font-size-medium;
  padding-bottom: 0.5em;
}

.comparePlans {
  padding: 1rem 0;
  display: grid;
  column-gap: 2em;
  row-gap: 2em;
  grid-template-columns: repeat(3, 1fr);

  @include breakpoint(screen-lg, max) {
    column-gap: 1em;
    row-gap: 1em;
  }

  @include breakpoint(screen-sm, max) {
    grid-template-columns: 1fr;
  }

  .footNotes {
    grid-column: 1 / span 3;

    @include breakpoint(screen-sm, max) {
      grid-column: 1 / span 1;
    }
  }
}

.currencyAndPeriod {
  display: flex;
  width: 100%;
  max-width: ($input-width * 2) + 2rem;

  align-items: center;
  justify-content: space-between;
}
