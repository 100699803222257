@import '../../styles/variables.scss';

.notes {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: $font-family-regular;
  font-size: $font-size-regular;

  .bullet {
    min-width: 2em;
  }

  .note {
    display: flex;
    white-space: pre-wrap;
    & + .note {
      padding-top: 1em;
    }
  }
}

.callToAction {
  padding: 1.625rem 1.875rem;
  background-color: $black-squeeze;
  font-size: $font-size-medium;
  font-family: $font-family-regular;

  .title {
    margin: 0 0 2rem 0;
    font-size: $font-size-extra-extra-large;
    font-family: $font-family-strong;
    white-space: pre-wrap;

    strong {
      color: $cerulean;
    }
  }

  .subtitle {
    margin: 0;
    font-size: $font-size-18;
    white-space: pre-wrap;
  }

  .features {
    padding: 0.5rem;
    list-style: none;

    .item {
      display: flex;
      align-items: center;

      .icon {
        font-size: $font-size-large;
        color: $cerulean;
        padding-right: 0.25em;
      }

      & + .item {
        padding-top: 0.5em;
      }
    }
  }
}

.apiDocsLink {
  display: flex;
  margin-top: 1rem;
  font-family: $font-family-regular;
  color: $cerulean;
}

.autoRenew {
  ul {
    margin: 0;
    padding: 0 1rem;
  }

  li {
    & + li {
      padding-top: 1em;
    }
  }
}
