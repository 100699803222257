@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';
@import '../../Account/mixins.scss';

$content-width-large: 52.5rem;

.portsDropdown {
  margin-bottom: 2rem;
  width: 100%;

  input {
    width: 100%;
    max-width: 100%;
  }
}

.form {
  width: 100%;
  max-width: 20rem;

  @include breakpoint(screen-md, max) {
    max-width: 100%;
  }

  .inputField {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    color: $navy-blue;

    .label {
      font-family: $font-family-regular;
      font-size: $font-size-medium;
      padding: 0 0 0.5em;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: 3rem;
  }
}

.blocker {
  $spinnerSize: 1em;
  font-size: $font-size-medium;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: $lightgrey;

  .lightBackgroundSpinner {
    @include spinner($spinnerSize, 0, $lightgrey, transparent);
  }

  .loaderInline {
    position: relative;
    width: $spinnerSize;
    height: $spinnerSize;
    padding-right: 0.5rem;
  }
}

.plansList {
  display: grid;
  max-width: $content-width-large;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin: 1rem 0 2rem;

  @include breakpoint(screen-lg, max) {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  @include breakpoint(screen-sm, max) {
    grid-template-columns: 1fr;
  }
}

.paymentDetailsTotal {
  color: $cerulean;
}

.footNotes {
  color: $navy-blue;
  margin: 1rem 0 2rem;
}

.selectedBundle {
  padding: 2rem 0 0.5rem;
}

.generateKeyContent {
  padding: 0.5rem 0 0.5rem;
}

.copyButton {
  width: 10rem;
  padding-right: 0;
  padding-left: 0;
}

.restrictedCurrencyInfo {
  font-size: $font-size-medium;
  color: $navy-blue;
  padding-bottom: 0.5em;
}
