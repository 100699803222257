@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.updateSubscriptionPage {
  font-size: $font-size-medium;

  > .section {
    padding-bottom: 2em;
  }

  > .section + .section {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: $font-size-extra-large;
    margin-bottom: 1.75rem;
  }

  .label {
    padding-bottom: 0.5em;
  }

  .chooseSubscription {
    padding-top: 1em;
    color: $cerulean;
    font-family: $font-family-regular;
  }
}
.blocker {
  font-size: $font-size-medium;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  z-index: 1;

  display: flex;
  flex-direction: colum;
  align-items: center;
  justify-content: center;

  color: $lightgrey;

  $spinnerSize: 1em;

  .lightBackgroundSpinner {
    @include spinner($spinnerSize, 0, $lightgrey, transparent);
  }

  .loaderInline {
    position: relative;
    width: $spinnerSize;
    height: $spinnerSize;
    padding-right: 0.5rem;
  }
}
