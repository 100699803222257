@import '../../styles/variables.scss';
@import '../mixins';

$input-width: 20rem;

.closeButton {
  @include clickable();
  position: absolute;
  right: 15px;
  top: 15px;
}

.closeButtonIcon {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
}

.content {
  padding: 8rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 54rem;

  .description {
    font-family: $font-family-regular;
    font-size: 18px;
    margin: 0;
    padding-bottom: 1rem;
    text-align: center;
    white-space: pre-wrap;
  }

  .title {
    font-size: $font-size-giant;
    margin: 0;
    text-align: center;
    max-width: 37.5rem;

    &Wide {
      max-width: 100%;
    }
    &SpaceBottom {
      margin-bottom: 2rem;
    }
  }

  .subTitle {
    font-size: $font-size-extra-extra-large;
    margin: 0.25rem 0 2rem;
    text-align: center;
    max-width: 37.5rem;
  }

  .singleTitle {
    font-size: $font-size-giant;
    font-size: clamp($font-size-giant / 2, 10vw, $font-size-giant);
    font-family: $font-family-strong;
    margin: 0;
    text-align: center;
    max-width: 60rem;
  }

  .completeAccountContent {
    width: 17.5rem;
  }
}

.formActions {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-family: $font-family-regular;
}

.submitButton {
  @include primaryAccountButton();
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-family: $font-family-strong;
  font-size: $font-size-medium;
  max-width: $input-width;
  &:active,
  &:focus {
    outline: none;
  }
}

.submitButtonSubText {
  text-align: center;
  margin-top: 1em;
  font-family: $font-family-strong;
  font-size: $font-size-regular;
}

.bottomContent {
  padding: 5rem 0 3rem;
  font-size: $font-size-medium;
  max-width: 28rem;

  .vesselLegendTitle {
    margin: 5rem 0 0;
  }

  .vesselLegendEntry {
    padding-top: 1em;
  }

  .bottomIcon {
    font-size: 10rem;
    opacity: 0.6;
  }

  .timeToTime {
    display: flex;
    justify-content: center;
    align-items: center;

    .timeIcon {
      font-size: 7rem;
      font-size: clamp(3.5rem, 14vw, 7rem);
      opacity: 0.6;
    }
  }

  .timeText {
    font-size: 4rem;
    font-size: clamp(2rem, 8vw, 4rem);
    opacity: 0.6;
  }
}
