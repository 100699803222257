@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

table.invoices {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: $font-size-medium;
  width: 100%;

  thead {
    background-color: $very-light-grey;
  }
  tbody {
    font-family: $font-family-regular;

    tr {
      border-bottom: 1px solid $very-light-grey;
      &:hover {
        background-color: $pale-blue;
      }
    }
  }

  td {
    padding: 1.2em;
    white-space: nowrap;

    .notAvailable {
      opacity: 50%;
    }

    &.download {
      text-align: right;

      a:link,
      a:visited {
        text-decoration: none;
        color: $cerulean;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .lgScreenOnly {
    @include breakpoint(screen-sm, max) {
      display: none;
    }
  }
}
